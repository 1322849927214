window.angular
    .module('hippo', [
        'angucomplete-alt',
        'hippoComponents',
        'sticky',
        'uuid',
        'hippo-consts',
        'ngCookies',
        'duScroll',
        'swipe',
        'ngTouch',
        'ordinal',
        'percentage',
        'currencyNumber',
        'comma',
        'displayFromOptions',
        'boriskozo.async-locks',
        'moneyFilter',
        'ngMessages',
        'ngDialog',
        'replacementCostRange',
        'abbreviate',
        'datePicker'
    ])
    .config(function configModule($locationProvider, ngDialogProvider, $httpProvider) {
        'ngInject';

        // initialize get if not there
        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }

        ngDialogProvider.setDefaults({
            className: 'hippo-dialog',
            plain: true,
            closeByEscape: true,
            showClose: false,
            closeByDocument: true,
            closeByNavigation: true
        });
        ngDialogProvider.setOpenOnePerName(false);

        // TODO this will break if we start having urls with multiple /s in them
        if (window.location.pathname.lastIndexOf('/') === 0) {
            const base = document.createElement('base');
            base.href = '/';
            document.getElementsByTagName('head')[0].appendChild(base);

            $locationProvider.html5Mode(true);
        }
    })
    .run(function ($window, $http) {
        'ngInject';
        $http.defaults.headers.post['Content-Type'] = 'text/plain';
        $window.FastClick.attach(document.body, {
            excludeNode: '^pac-'
        });
        $window.$($window.document).on('focus', 'input, textarea', function () {
            $window.$($window.document.body).addClass('when-keyboard-showing');
        });
        $window.$($window.document).on('blur', 'input, textarea', function () {
            $window.$($window.document.body).removeClass('when-keyboard-showing');
        });
    })
    .controller(
        'pageController',
        function (
            $scope,
            pageGenerator,
            $location,
            $timeout,
            $http,
            HIPPO_CONSTS,
            $window,
            $sce,
            pagesService,
            modalsService,
            $log,
            navService,
            featureToggle,
            apiService,
            $q,
            utilsService,
            WorkflowPlan,
            userDataService,
            ngDialog,
            cacheService,
            fullStoryService,
            producerInFlow,
            userActivityService,
            analyticsService,
            partnerService
        ) {
            'ngInject';
            const { _, angular, $, moment, sessionStorage } = window;
            navService.initialize($scope);

            const flags = userDataService.getFlags();
            $scope.flags = flags;

            // easy for development
            if (flags.modal) {
                $timeout(function () {
                    $scope.showModal(flags.modal);
                });
            }

            const loadedPending = [];

            /**
             * Retrieves a new set of pages, clean of previous data.
             *
             * On first run will include page 0 to dom after 2 seconds.
             * On consecutive runs will make sure page 0 remains included on dom by using previous values.
             */
            $scope.startAddress = '';
            $scope.isNewDesign = featureToggle.showNewDesign();
            $scope.theme = $scope.isNewDesign ? 'new-design dark-mode' : '';
            $scope.saving = false;
            $scope.showSaving = false;
            $scope.loaded = false;
            $scope.activeModal = false;
            $scope.activeModalShowing = false;
            $scope.animating = false;
            $scope.defaultProduct = featureToggle.getDefaultProduct();
            $scope.defaultState = featureToggle.getDefaultState();
            $scope.defaultCarrier = featureToggle.getDefaultCarrier();
            $scope.hideChat = () => featureToggle.isPartnerFlow() && partnerService.hideLivechat;
            if (featureToggle.isPartnerFlow()) {
                partnerService.loadScripts();
            }
            $scope.flowCustomizationKey = () => {
                return featureToggle.getFlowCustomizationKey();
            };
            const raterVersion = $scope.additionalInfo ? $scope.additionalInfo.rater_version : undefined;
            $scope.modals = WorkflowPlan.getModals(
                $scope.defaultState,
                $scope.defaultProduct,
                $scope.defaultCarrier,
                $scope.flowCustomizationKey(),
                raterVersion
            );
            $scope.confirmedCoverages = [];
            $scope.pages = WorkflowPlan.getPages(
                $scope.defaultState,
                $scope.defaultProduct,
                $scope.defaultCarrier,
                $scope.flowCustomizationKey(),
                raterVersion
            );
            $scope.activePage = featureToggle.getActivePage();
            $scope.coverageType = '';

            // enable access directly to page.. makes mobile testing much easier
            if ($scope.activePage < $scope.pages.length) {
                $scope.pages[$scope.activePage].includeOnDom = true;
            }

            $scope.pagePercent = 0;
            $scope.showBackgroundExpandAnimation = false;
            $scope.product = 'home';
            $scope.confirmation = {}; // guy: must be initialized to an object
            $scope.confirmationLarge = false;
            $scope.confirmationSmall = false;
            $scope.loadingApi = false;
            $scope.landing = {
                address: false,
                nestedSnapIndex: 0,
                needApt: false,
                needAptMsg: false,
                email: '',
                emailSaved: false
            };
            $scope.ignoreableRequestCounter = 0;
            $scope.currentYearMinusFive = moment().subtract(5, 'years').format('YYYY');
            $scope.missingFields = [];
            $scope.claimsToAdd = [];
            $scope.progressiveLinkToAdd = '';
            $scope.claimsTitle = "We've found two past claims";
            $scope.data = {};
            $scope.data.showPaymentBreakdown = false;
            $scope.data.showEffectiveCoverageDate = false;
            $scope.data.showMobileNav = false;
            $scope.data.addressVerified = false;
            $scope.backendFlags = {};
            $scope.notionDiscount = false;
            $scope.data.producerUrl = apiService.lead.getProducerUrl(featureToggle.flags.producer_id).then((result) => {
                $scope.producerUrl = result.data.url;
            });

            if (featureToggle.isParametersInUrl()) {
                // keep query synced with leadId
                $scope.$watch('leadId', () => {
                    if ($scope.leadId) {
                        // support multiple tabs with different leads.
                        $location.search().leadId = $scope.leadId;
                    }
                });
            } else {
                // Remove the following parameters from the url.
                $location.search('leadId', null);
                $location.search('prefilledAddress', null);
            }

            $scope.isLiveChat = featureToggle.isLivechatOn;

            const getSmartHomeDiscountValue = (type = 'starterDiscount') => {
                const discount = {
                    starterDiscount: ['property_data.smart_home_starter_kit'],
                    simplisafeDiscount: [
                        'property_data.smart_home_starter_kit',
                        'property_data.smart_home_pro_monitoring_kit'
                    ]
                };
                $scope.loadingApi = true;
                apiService.lead
                    .getSimplisafeSmartHomeDiscount($scope.leadId, discount[type])
                    .then(function (response) {
                        if (!response || !response.data) {
                            $scope.loadingApi = false;
                            return;
                        }
                        $scope.leadData.property_data.simplisafeDiscount =
                            type === 'simplisafeDiscount' &&
                            response.data.property_data.smart_home_pro_monitoring_kit.discount;
                        $scope.leadData.property_data.starterDiscount =
                            response.data.property_data.smart_home_starter_kit.discount;
                        $scope.loadingApi = false;
                    })
                    .catch(function (err) {
                        $log.error(err);
                        $scope.loadingApi = false;
                    });
            };

            $scope.getInitialState = () => {
                const result = {
                    personal_information: {
                        previous_claims: false,
                        second_insured: {}
                    },
                    property_data: {
                        // DEFAULT_PROPERTY_DATA_PURCHASE_DATE
                        purchase_date: 'over_1_year_ago',
                        residence_type: 'primary',
                        affinity_name: featureToggle.isXfinityFlow() ? 'Comcast' : ''
                    },
                    additional_insured: [],
                    loss_history: [],
                    product: flags.product ? flags.product : $scope.defaultProduct
                };

                if (featureToggle.showHippoHomeCareStandalonePage() || featureToggle.showHippoHomeCareServicePage()) {
                    result.property_data.hippo_home_care_opt_in = true;
                }

                if (featureToggle.showHippoHomeCareServicePage()) {
                    result.checkout_data = {
                        notify_mortgage_lender: true
                    };
                }

                if (_.get($scope.leadData, 'property_data.community_id')) {
                    // builder flow.
                    result.property_data.purchase_date = 'next_3_months';
                    result.property_data.model_id = $scope.leadData.property_data.model_id;
                    result.model_details = $scope.leadData.model_details; // support other. send to the backend
                    result.property_data.community_id = $scope.leadData.property_data.community_id; // even though we don't use it, keep it here otherwise looks bad when clicking "next"
                    result.organization_is_builder = true;
                    // no need to product here..
                }
                return result;
            };

            $scope.leadData = $scope.getInitialState();

            // guy: this is a quick fix for the angular scope prototypical inheritance
            // read more at: http://stackoverflow.com/questions/26634588/why-does-angular-binding-breaks-between-directives-when-transcluded
            $scope.setConfirmation = function (confirmation) {
                $scope.confirmation = confirmation;
                $scope.totalMortgageUpdates = confirmation.total_mortgage_updates;
                $scope.reachedLenderUpdateLimit = confirmation.total_mortgage_updates >= 3;
                $scope.mortgages = confirmation.mortgages;
            };

            $scope.setConfirmationProgressiveLink = function (link) {
                $scope.confirmationProgressiveLink = link;
            };

            $scope.startNewLeadWithAnimation = function ({ value, validated }) {
                const addressVerified = _.isObject(value) && validated;
                $scope.leadData.personal_information = {
                    address: window._.cloneDeep(value)
                };
                const raterVersion = $scope.additionalInfo ? $scope.additionalInfo.rater_version : undefined;
                $scope.pages = WorkflowPlan.getPages(
                    value.state,
                    $scope.leadData.product,
                    $scope.leadData.carrier || $scope.defaultCarrier,
                    $scope.flowCustomizationKey(),
                    raterVersion
                );
                navService.clearSession();
                $('.burger-container.burger-back-btn').hide();

                const pageKeyExistsInWorkflow = (key) => !!$scope.pages.find((p) => p.key === key);
                const communitySelectPageKey = pagesService.communitySelectPage.get().key;

                if (!addressVerified) {
                    if (value && !_.isEmpty(value)) {
                        $scope.activePage = -0.5;
                        $scope.showFooter = false;
                        navService.animateToPage(pagesService.yourAddressPage.get().key, 0.5);
                    }
                } else if (pageKeyExistsInWorkflow(communitySelectPageKey) && partnerService.isBuilder) {
                    $scope.activePage = -0.5;
                    $scope.showFooter = false;
                    navService.animateToPage(communitySelectPageKey, 0.5);
                } else if (addressVerified) {
                    $scope.activePage = -0.5;
                    $scope.showFooter = false;
                    navService.animateToPage(pagesService.productSelectPage.get().key, 0.5);
                }
            };

            $scope.isProducerFlow = () => userDataService.isProducerQuote();

            $scope.isBuilderQuote = () => partnerService.isBuilder;

            $scope.isAnimationOn = () => featureToggle.isAnimationOn();

            $scope.isXfinityFlow = () => featureToggle.isXfinityFlow();

            $scope.isSimplisafeCoBrandFlow = () => featureToggle.isSimplisafeCoBranded();

            $scope.showPartnerFlowHeader = () => featureToggle.showPartnerFlowHeader();

            $scope.isKangarooCoBrandFlow = () => featureToggle.isKangarooCoBranded();

            $scope.isBuilderPrequoteFlow = () => featureToggle.isBuilderPrequoteFlow();

            $scope.isShowNewMobileFlow = () => featureToggle.isShowNewMobileFlow();

            $scope.showPrelimQuoteNewDesignMobileChanges = () => featureToggle.showPrelimQuoteNewDesignMobileChanges();

            $scope.showNewDesignDesktopChanges = () => featureToggle.showNewDesignDesktopChanges();

            $scope.showNewMobileLandingPageChanges = () => featureToggle.showNewMobileLandingPageChanges();

            $scope.showSimplifiedMobileLandingPage = () => featureToggle.showSimplifiedMobileLandingPage();

            let cancelLastSaveRequest = null;
            let alreadyShownCosmeticDamageModalOnce = false;
            $scope.save = function (moveToNextPage, forPage, navToPage) {
                if (typeof forPage === 'undefined') {
                    forPage = $scope.activePage;
                }
                producerInFlow.sendQuickNavPageSaveEvent({
                    page: forPage,
                    leadData: $scope.leadData,
                    template: $scope.pages[forPage].key
                });

                // if we are going to create the lead on next step, lets reset all information
                const isProductSelectPage = $scope.pages[forPage].key === 'product-select';
                const isNextPagePropertyUsage = isProductSelectPage && $scope.leadData.product === 'HO-6';
                const isNextPageYourHome = isProductSelectPage && featureToggle.isPrefillFlow();
                const nextPage = isProductSelectPage && !isNextPagePropertyUsage && !isNextPageYourHome ? 2 : 1;
                const isHo3 = _.get($scope.leadData, 'product') === 'HO-3';

                const getNextPage = function (currentPage) {
                    if (!moveToNextPage) {
                        return currentPage in $scope.pages ? $scope.pages[currentPage].key : '';
                    }

                    let nextPageIndex = navToPage || forPage + 1;
                    const shouldSkipPage = function (p) {
                        let skipPage = false;
                        if (typeof $scope.pages[p].skipPage === 'function') {
                            const compiledPage = $scope.getPageView($scope.pages[p], false);
                            skipPage = compiledPage.skipPage;
                        } else {
                            skipPage = $scope.pages[p].skipPage;
                        }
                        return skipPage;
                    };

                    while (shouldSkipPage(nextPageIndex) && nextPageIndex < $scope.pages.length) {
                        nextPageIndex = nextPageIndex + 1;
                    }

                    return $scope.pages[nextPageIndex] ? $scope.pages[nextPageIndex].key : '';
                };

                if (navService.pageCreatesLead(forPage + nextPage) || (isNextPagePropertyUsage && $scope.leadId)) {
                    const hasSubProduct = _.get($scope, 'leadData.sub_product');
                    const isOwnerOccupied = hasSubProduct === 'owner_occupied';
                    if (_.get($scope, 'leadData.personal_information.address.county')) {
                        delete $scope.leadData.personal_information.address.county;
                    }
                    if (hasSubProduct && isProductSelectPage) {
                        delete $scope.leadData.sub_product;
                    }
                    if (isHo3 && hasSubProduct && isOwnerOccupied) {
                        delete $scope.leadData.sub_product;
                    }
                    navService.clearSession();
                }
                if ($scope.leadId) {
                    $scope.saving = true;
                    $timeout(function () {
                        if ($scope.saving) {
                            $scope.showSaving = true;
                        }
                    }, 1000);
                    $scope.err = false;

                    const data = _.clone($scope.leadData);
                    delete data.product;
                    delete data.quote;

                    for (const key in data) {
                        for (const subkey in data[key]) {
                            $scope.processOutgoingValue(data, key, subkey);
                        }
                    }

                    let dataFiltered = data;
                    const page = $scope.getPageView($scope.pages[forPage]);

                    if (page) {
                        dataFiltered = {};

                        const setField = function (field) {
                            if (field.sendToServerOnPageSave === false) {
                                return;
                            }

                            if (field.deepKey) {
                                if (field.deepKey === 'loss_history') {
                                    // todo: need to find a proper 'type' here
                                    dataFiltered.loss_history = JSON.parse(
                                        JSON.stringify(
                                            $scope.leadData.loss_history.filter((c) => c.source !== 'report')
                                        )
                                    );
                                    dataFiltered.loss_history = dataFiltered.loss_history.concat(
                                        _.map($scope.claimsToAdd, (claim) => {
                                            return {
                                                date: claim.date,
                                                category: claim.category,
                                                amount: claim.amount,
                                                names: claim.names,
                                                source: 'user'
                                            };
                                        })
                                    );
                                } else {
                                    // assign as is..
                                    if (window._.get($scope.leadData, field.deepKey) !== undefined) {
                                        // GUY -  adding this as it sets empty objects and causes invalid schema.
                                        window._.set(
                                            dataFiltered,
                                            field.deepKey,
                                            window._.get($scope.leadData, field.deepKey)
                                        );
                                    }
                                }
                            } else {
                                $log.info('field is missing deep key', field);
                            }
                        };

                        const fields =
                            page.fields || page.questions || (page.type === 'tiles' && page.multi && page.tiles);

                        if (fields) {
                            for (let i = 0; i < fields.length; i++) {
                                setField(fields[i]);
                            }
                        }
                        if (page.type === 'tiles' && !page.multi) {
                            setField(page.tiles[0]);
                        }
                        if (page.extraFields) {
                            for (let i = 0; i < page.extraFields.length; i++) {
                                setField(page.extraFields[i]);
                            }
                        }
                        if (page.key === 'about-yourself') {
                            // send email and phone number if they're on the leadData even if the fields are hidden on the personal_information page
                            dataFiltered.personal_information.email = $scope.leadData.personal_information.email;
                            dataFiltered.personal_information.phone_number =
                                $scope.leadData.personal_information.phone_number;
                            dataFiltered.personal_information.second_insured =
                                $scope.leadData.personal_information.second_insured;
                            if ($scope.leadData.personal_information.second_insured.date_of_birth === '') {
                                dataFiltered.personal_information.second_insured.date_of_birth = null;
                            }
                        }
                        if (page.key === 'discounts-1') {
                            const coverageA = _.get(data, 'adjustments.dwelling_rebuilding.value');
                            const finalQuotePageIndex = $scope.pages.findIndex((page) => page.key === 'final-quote');
                            if (
                                $scope.leadData.personal_information.selected_plan === 'recommended' &&
                                featureToggle.isAnyEbslTestGroup(coverageA)
                            ) {
                                if ($scope.leadData.adjustments.systems_coverage) {
                                    $scope.leadData.adjustments.systems_coverage.value = 'No';
                                }
                                if ($scope.leadData.adjustments.service_line) {
                                    $scope.leadData.adjustments.service_line.value = 'No';
                                }
                                $scope.save(false, finalQuotePageIndex);
                            }
                            if (featureToggle.showSimplisafeInflowDesign() || featureToggle.showSimplisafeBasicFlow()) {
                                getSmartHomeDiscountValue('simplisafeDiscount');
                            }
                            if ($scope.leadData.property_data.year_plumbing_updated === false) {
                                dataFiltered.property_data.year_plumbing_updated = null;
                            }
                        }
                        if (page.key === 'prelim-discounts') {
                            delete dataFiltered.personal_information;
                            // at simplisafe and adt co-brand flow need literately send smart_home_pro_monitoring_kit to be true as simplisafe/adt tile set true as default,
                            if ($scope.leadData.property_data.smart_home_expected_existing_partner_customer) {
                                if (featureToggle.isSimplisafeCoBranded()) {
                                    dataFiltered.property_data.smart_home_pro_monitoring_kit = true;
                                    dataFiltered.property_data.smart_home_selected_kit = 'simplisafe_pro';
                                } else if (featureToggle.isAdtCoBranded()) {
                                    dataFiltered.property_data.smart_home_pro_monitoring_kit = true;
                                    dataFiltered.property_data.smart_home_selected_kit = 'adt_pro';
                                }
                            } else if (
                                _.get($scope.leadData, 'property_data.smart_home_primary_kit_provider') &&
                                !$scope.leadData.property_data.smart_home_selected_kit
                            ) {
                                dataFiltered.property_data.smart_home_selected_kit = `${$scope.leadData.property_data.smart_home_primary_kit_provider}_self`;
                            }
                        }
                        if (page.key === 'hippo-home-care') {
                            dataFiltered.property_data = {
                                hippo_home_care_opt_in: $scope.leadData.property_data.hippo_home_care_opt_in
                            };
                        }
                        if (page.key === 'smart-monitoring-discount') {
                            dataFiltered.property_data = {
                                smart_home_selected_kit: $scope.leadData.property_data.smart_home_selected_kit,
                                smart_home_pro_monitoring_kit:
                                    $scope.leadData.property_data.smart_home_pro_monitoring_kit,
                                smart_home_starter_kit:
                                    $scope.leadData.property_data.smart_home_starter_kit ||
                                    $scope.leadData.property_data.smart_home_pro_monitoring_kit
                            };
                        }
                        if (page.key === 'kangaroo') {
                            dataFiltered.property_data = {
                                smart_home_selected_kit: $scope.leadData.property_data.smart_home_selected_kit,
                                smart_home_starter_kit: true,
                                smart_home_expected_existing_partner_customer: true
                            };
                        }
                        if (page.key === 'smart-home-discount') {
                            dataFiltered.property_data = {
                                smart_home_starter_kit:
                                    $scope.leadData.property_data.smart_home_starter_kit ||
                                    $scope.leadData.property_data.smart_home_pro_monitoring_kit
                            };

                            if ($scope.leadData.checkout_data.smart_home_terms_accepts !== undefined) {
                                dataFiltered.checkout_data = {
                                    smart_home_terms_accepts: $scope.leadData.checkout_data.smart_home_terms_accepts
                                };
                            }
                        }
                        if (page.key === 'progressive-integration') {
                            dataFiltered.auto_insurance_partner = {
                                partner_name: $scope.leadData.auto_insurance_partner.partner_name
                            };
                        }
                        if (page.key === 'confirmation') {
                            dataFiltered.property_data = {
                                mortgages: $scope.leadData.property_data.mortgages
                            };
                        }
                        if (page.key === 'services') {
                            dataFiltered.checkout_data = {
                                cancellation_assistance: $scope.leadData.checkout_data.cancellation_assistance,
                                notify_mortgage_lender: $scope.leadData.checkout_data.notify_mortgage_lender
                            };
                            dataFiltered.property_data = {
                                hippo_home_care_opt_in: $scope.leadData.property_data.hippo_home_care_opt_in
                            };
                        }

                        if (page.key === 'final-quote') {
                            // todo: use the modal definition to decide which adjustments to send
                            dataFiltered.adjustments = {};
                            _.each($scope.leadData.adjustments, (value, key) => {
                                dataFiltered.adjustments[key] = value.value;
                            });
                            dataFiltered.personal_information = {
                                effective_date: $scope.leadData.personal_information.effective_date
                            };
                            dataFiltered.mine_subsidence_disclaimer_read =
                                $scope.leadData.mine_subsidence_disclaimer_read; // HIP-5088
                            if ($scope.leadData.property_data.smart_home_starter_kit_activation_days) {
                                dataFiltered.property_data = {
                                    smart_home_starter_kit: $scope.leadData.property_data.smart_home_starter_kit
                                };

                                if ($scope.leadData.property_data.cosmetic_damage_exclusion !== undefined) {
                                    dataFiltered.property_data.cosmetic_damage_exclusion =
                                        $scope.leadData.property_data.cosmetic_damage_exclusion;
                                }

                                if ($scope.leadData.property_data.smart_home_pro_monitoring_kit !== undefined) {
                                    dataFiltered.property_data.smart_home_pro_monitoring_kit =
                                        $scope.leadData.property_data.smart_home_pro_monitoring_kit;
                                }

                                if ($scope.leadData.checkout_data.smart_home_terms_accepts !== undefined) {
                                    dataFiltered.checkout_data = {
                                        smart_home_terms_accepts: $scope.leadData.checkout_data.smart_home_terms_accepts
                                    };
                                }

                                if ($scope.leadData.property_data.smart_home_selected_kit !== undefined) {
                                    dataFiltered.property_data.smart_home_selected_kit =
                                        $scope.leadData.property_data.smart_home_selected_kit;
                                }

                                if (
                                    _.get($scope.leadData, 'property_data.smart_home_primary_kit_provider') &&
                                    !$scope.leadData.property_data.smart_home_selected_kit
                                ) {
                                    dataFiltered.property_data.smart_home_selected_kit = `${$scope.leadData.property_data.smart_home_primary_kit_provider}_self`;
                                }

                                const hurricaneScreenedEnclosures = _.get(
                                    $scope.leadData,
                                    'optional_coverages.hurricane_screened_enclosures'
                                );
                                if (hurricaneScreenedEnclosures !== undefined) {
                                    _.set(
                                        dataFiltered,
                                        'optional_coverages.hurricane_screened_enclosures',
                                        hurricaneScreenedEnclosures
                                    );
                                }
                            }

                            const landlordPersonalInjury = _.get(
                                $scope.leadData,
                                'optional_coverages.landlord_personal_injury.value'
                            );
                            if (landlordPersonalInjury !== undefined) {
                                _.set(
                                    dataFiltered,
                                    'optional_coverages.landlord_personal_injury',
                                    landlordPersonalInjury
                                );
                            }
                            const leadLiability = _.get($scope.leadData, 'optional_coverages.lead_liability');
                            if (leadLiability !== undefined) {
                                _.set(dataFiltered, 'optional_coverages.lead_liability', leadLiability);
                            }

                            // cosmetic_damage_exclusion is readonly if required
                            if ($scope.leadData.property_data.cosmetic_damage_exclusion_required) {
                                delete dataFiltered.property_data.cosmetic_damage_exclusion;
                            }
                            if ($scope.leadData.property_data.ready_for_risk_score) {
                                if (!dataFiltered.property_data) {
                                    dataFiltered.property_data = {};
                                }
                                dataFiltered.property_data.ready_for_risk_score =
                                    $scope.leadData.property_data.ready_for_risk_score;
                            }
                        }
                        if (
                            (page.key === 'checkout' || page.key === 'final-quote') &&
                            $scope.leadData.property_data.visible_damage
                        ) {
                            dataFiltered.property_data.visible_damage = true;
                            if (page.key === 'final-quote') {
                                delete dataFiltered.personal_information;
                                delete dataFiltered.adjustments;
                                delete dataFiltered.property_data.smart_home_starter_kit;
                                delete dataFiltered.property_data.smart_home_selected_kit;
                                delete dataFiltered.optional_coverages;
                                delete dataFiltered.property_data.smart_home_pro_monitoring_kit;
                                delete dataFiltered.property_data.ready_for_risk_score;
                                delete dataFiltered.checkout_data;
                            } else {
                                delete dataFiltered.personal_information;
                                delete dataFiltered.property_data.go_paperless;
                            }
                            const nextPageKey = getNextPage(forPage);
                            apiService.lead
                                .postLead($scope.leadId, {
                                    lead_data: dataFiltered,
                                    page: 'home-details',
                                    next_page: nextPageKey,
                                    quote_response: false
                                })
                                .catch((err) => $scope.handleError(err));
                            return;
                        }
                    }
                    dataFiltered = _.clone(dataFiltered);
                    for (const key in dataFiltered) {
                        for (const subkey in dataFiltered[key]) {
                            $scope.processOutgoingValue(dataFiltered, key, subkey);
                        }
                    }
                    if (!moveToNextPage) {
                        $scope.ignoreableRequestCounter++;
                    }

                    const ignoreableRequestCounter = $scope.ignoreableRequestCounter;

                    if (cancelLastSaveRequest !== null) {
                        // cancelLastSaveRequest();
                        cancelLastSaveRequest = null;
                    }
                    $scope.loadingApi = true;
                    const nextPageKey = getNextPage(forPage);
                    cancelLastSaveRequest = apiService.lead
                        .postLead($scope.leadId, {
                            lead_data: dataFiltered,
                            page: $scope.pages[forPage].key,
                            next_page: nextPageKey,
                            quote_response: !!$scope.pages[forPage].quoteResponse
                        })
                        .then(
                            function (response) {
                                // Clear claimsToAdd array after clicking 'Continue' so that
                                // the UI doesn't show both the newly added claim along with the
                                // claimsToAdd fields at the same time.
                                $scope.claimsToAdd = []; // todo: need a way to remove this from here.

                                cancelLastSaveRequest = null;
                                $scope.loadingApi = false;

                                // this will ignore the return of a request is another request has been made between when the original request was sent and the response was recieved
                                if (moveToNextPage || $scope.ignoreableRequestCounter === ignoreableRequestCounter) {
                                    $scope.processIncomingData(response.data);
                                    $scope.stopSaving();
                                    if ($scope.deductibleChangedAutomatically) {
                                        $scope.additionalInfo.deductible_changed_automatically = true;
                                    }
                                    if (
                                        response.data.lead_data.quote &&
                                        ($scope.pages[forPage].key === 'final-quote' ||
                                            $scope.pages[forPage].key === 'checkout')
                                    ) {
                                        $scope.processQuote(response.data.lead_data.quote);
                                        $scope.leadData.quote.adjustments = _.clone(
                                            response.data.lead_data.adjustments
                                        );
                                        if (
                                            $scope.pages[forPage].key === 'final-quote' &&
                                            $scope.leadData.property_data.smart_home_starter_kit_activation_days
                                        ) {
                                            getSmartHomeDiscountValue('simplisafeDiscount');
                                        }
                                    }
                                    if (page.key === 'your-home') {
                                        if (
                                            _.get(
                                                data,
                                                'property_data.cosmetic_damage_roof_surfacing_exclusion_required'
                                            ) &&
                                            !alreadyShownCosmeticDamageModalOnce
                                        ) {
                                            moveToNextPage = false;
                                            $scope
                                                .showModal('cosmeticDamageDisclaimerModal')
                                                .closePromise.then((event) => {
                                                    if (event.value) {
                                                        moveToNextPage = true;
                                                        alreadyShownCosmeticDamageModalOnce = true;
                                                        $scope.next();
                                                    }
                                                });
                                        }
                                    }
                                    if (moveToNextPage !== false) {
                                        const markAsDone = function (i) {
                                            $timeout(function () {
                                                $scope.pages[i].done = true;
                                                $scope.pages[i].successfulSave = response.data;
                                            }, 1000);
                                        };
                                        markAsDone(forPage);
                                        const nextPage = navToPage || forPage + 1;
                                        navService.animateToPage(nextPage, 0.5);
                                    }
                                }
                            },
                            function (err) {
                                cancelLastSaveRequest = null;
                                $scope.loadingApi = false;

                                $scope.stopSaving();
                                if (err.status !== 405 || moveToNextPage) {
                                    if (featureToggle.isProducerFlow() && err.data.loss_history) {
                                        $scope.leadData.loss_history = err.data.loss_history;
                                        $scope.leadData.tooManyClaims = true;
                                        $scope.claimsError = err;
                                        $scope.handleError(err);
                                    } else if (
                                        dataFiltered.personal_information &&
                                        dataFiltered.personal_information.previous_address
                                    ) {
                                        $scope.leadData.personal_information.previous_address = _.extend(
                                            {
                                                invalid: true
                                            },
                                            $scope.leadData.personal_information.previous_address
                                        ); // this is to trigger a watch
                                        if (page.key === 'claim-check') {
                                            $scope.handleError(err);
                                        }
                                    } else {
                                        $scope.handleError(err);
                                    }
                                }
                            }
                        );
                } else {
                    if (moveToNextPage !== false) {
                        const markAsDone = function (i) {
                            $timeout(function () {
                                $scope.pages[i].done = true;
                            }, 1000);
                        };
                        markAsDone(forPage);
                        // This gets called on product-select page and on how-property-is-used page.
                        // If user is in HO5 flow, then navigate to yourHome page. otherwise
                        // navigate to the next page (how-property-is-used and then your-home).
                        if ($scope.leadData.product === 'HO-5') {
                            if (featureToggle.isDp3SupportedState($scope.leadData.personal_information.address.state)) {
                                navService.animateToPage(forPage + 1, 0.5);
                            } else {
                                navService.animateToPage(pagesService.yourHomePage.get().key, 0.5);
                            }
                        } else {
                            navService.animateToPage(forPage + 1, 0.5);
                        }
                    }
                }
            };

            $scope.processIncomingValue = function (key, subkey, value) {
                if (angular.isUndefined($scope.leadData[key])) {
                    $scope.leadData[key] = {};
                }
                if (value !== null) {
                    $scope.leadData[key][subkey] = value;
                }
            };

            $scope.processOutgoingValue = function (data, key, subkey) {
                if (subkey === 'date_of_birth') {
                    try {
                        data[key][subkey] = moment(data[key][subkey], 'MM/DD/YYYY').format('MM/DD/YYYY');
                    } catch (e) {
                        delete data[key][subkey];
                    }
                    if (data[key][subkey] === 'Invalid date') {
                        delete data[key][subkey];
                    }
                }
                if (subkey === 'business_phone_number' && data[key][subkey] && data[key][subkey].length === 10) {
                    data[key][subkey] =
                        data[key][subkey].substr(0, 3) +
                        '-' +
                        data[key][subkey].substr(3, 3) +
                        '-' +
                        data[key][subkey].substr(6);
                }
                if (subkey === 'payment_method') {
                    if (data[key][subkey] === 'Credit / Debit') {
                        data[key][subkey] = 'cc';
                    }
                    if (data[key][subkey] === 'Mortgage Bill') {
                        data[key][subkey] = 'escrow';
                    }
                }
            };

            $scope.processIncomingData = function (data) {
                data = _.clone(data);
                const leadData = data.lead_data;

                if (
                    featureToggle.setDefaultPaymentPlanForCheckoutOnMobileOnly() &&
                    leadData.checkout_data &&
                    leadData.checkout_data.payment_method === 'cc'
                ) {
                    leadData.checkout_data.payment_frequency = 'monthly';
                }

                if (data.lead_data.checkout_data && $scope.checkoutData) {
                    // if the user has already changed these value the backend should not override them
                    delete data.lead_data.checkout_data.payment_method;
                    delete data.lead_data.checkout_data.payment_frequency;
                }

                if (_.get(data, 'lead_data.personal_information.date_of_birth') === '01/01/0001') {
                    delete data.lead_data.personal_information.date_of_birth;
                }

                for (const key in leadData) {
                    if (!_.isPlainObject(leadData[key])) {
                        $scope.leadData[key] = leadData[key];
                        continue;
                    }
                    for (const subkey in leadData[key]) {
                        if (_.isObject(leadData[key][subkey]) && 'is_default' in leadData[key][subkey]) {
                            if ('value' in leadData[key][subkey]) {
                                $scope.processIncomingValue(key, subkey, leadData[key][subkey].value);
                            }
                        } else if (_.isObject(leadData[key][subkey]) && 'prefilled_value' in leadData[key][subkey]) {
                            $scope.processIncomingValue(key, subkey, leadData[key][subkey].prefilled_value);
                        } else {
                            $scope.processIncomingValue(key, subkey, leadData[key][subkey]);
                        }
                    }
                }

                if (data.insuranceApplicationId) {
                    $scope.data.insuranceApplicationId = data.insuranceApplicationId;
                }

                if (data.product) {
                    $scope.leadData.product = data.product;
                }
                if (_.isObject(data.additionalInfo)) {
                    $scope.additionalInfo = _.extend($scope.additionalInfo, data.additionalInfo);
                }
                if (data.quote) {
                    $scope.leadData.quote = data.quote;
                }
                if (data.lead_data.checkout_data) {
                    $scope.checkoutData = _.assign($scope.checkoutData, data.lead_data.checkout_data);
                }

                try {
                    sessionStorage.setItem('leadData', JSON.stringify($scope.leadData));
                } catch (e) {
                    $log.info('sessionStorage error');
                }
                $scope.lastLeadDataCopy = JSON.parse(JSON.stringify($scope.leadData));

                if (data.missing_fields) {
                    $scope.missingFields = _.clone(data.missing_fields);
                }

                if (data.flags) {
                    $scope.backendFlags = data.flags;
                }

                $scope.quickNavEnabled = producerInFlow.setQuickNavPageNavigateListener({
                    navService,
                    closeNav,
                    getActivePageObj,
                    savePage: $scope.save
                });

                $scope.backButtonAlt = 'Back Button';
                $scope.backButtonImage = featureToggle.showNewDesign()
                    ? '/img/new-design/hippo-icon-back-button.svg'
                    : featureToggle.isHomePointFinancialFlow()
                    ? '/img/back-blue.png'
                    : '/img/back.svg';
                $scope.backButtonReady = 'true';
                if ($scope.quickNavEnabled) {
                    $scope.backButtonImage = '/img/nav.svg';
                    $scope.backButtonAlt = 'Nav Button';
                }
            };

            $scope.convertLiveAddressToAddress = function (liveaddress) {
                if (liveaddress.error) {
                    return undefined;
                } else if (liveaddress.components) {
                    const address = {
                        street: liveaddress.delivery_line_1,
                        street2: liveaddress.delivery_line_2,
                        city: liveaddress.components.city_name,
                        state: liveaddress.components.state_abbreviation,
                        zip: liveaddress.components.zipcode
                    };
                    return address;
                } else if (typeof liveaddress === 'object') {
                    return liveaddress;
                } else if (typeof liveaddress === 'string') {
                    return undefined;
                }
            };

            $scope.loadPreviousSave = function (page, back) {
                // apiService.lead.postLead($scope.leadId, {lead_data:{}, quote_response:($scope.pages[page].type !== 'error' && page > 4)})
                $scope.loadingApi = true;
                apiService.lead
                    .getLead($scope.leadId)
                    .then(
                        function (response) {
                            $scope.processIncomingData(response.data);
                            try {
                                // if the lead is bound, we also get the confirmation information here
                                if (response.data.confirmation_number) {
                                    if (window.location.href.indexOf('confirmation') === -1) {
                                        // Show error page when user with a confirmation number accesses an earlier page in the flow [HIP-7675]
                                        return $scope.handleError({
                                            data: {
                                                type: 'alreadyPurchased'
                                            }
                                        });
                                    }
                                    $scope.setConfirmation(response.data);
                                }
                            } catch (e) {
                                $log.error('failed to handle bound lead loadPreviousSave', e);
                            }

                            // on refresh sync the UI to correct product, the UI defaults to HO-3, so for HO-6 we must adjust
                            $scope.carrier = response.data.lead_data.carrier;
                            const raterVersion = $scope.additionalInfo
                                ? $scope.additionalInfo.rater_version
                                : undefined;
                            $scope.pages = WorkflowPlan.getPages(
                                response.data.lead_data.personal_information.address.state,
                                response.data.product,
                                $scope.carrier,
                                $scope.flowCustomizationKey(),
                                raterVersion
                            );
                            $scope.modals = WorkflowPlan.getModals(
                                response.data.lead_data.personal_information.address.state,
                                response.data.product,
                                $scope.carrier,
                                $scope.flowCustomizationKey(),
                                raterVersion
                            );
                            $scope.stopSaving();
                            $scope.loadingApi = false;
                            navService.animateToPage(page, back ? -0.5 : 0.5);

                            if (featureToggle.isRetargetingFlow() && !userDataService.isFromHippoCom()) {
                                userDataService.updateCookies();
                            }
                        },
                        function (err) {
                            $scope.loadingApi = false;
                            $scope.handleError(err);
                        }
                    )
                    .then(() => {
                        if (
                            featureToggle.showSimplisafeInflowDesign() ||
                            featureToggle.isProducerFlow() ||
                            featureToggle.showSimplisafeBasicFlow()
                        ) {
                            getSmartHomeDiscountValue('simplisafeDiscount'); // need leadId first to be called
                        }
                    });
            };

            $scope.getDownloadQuoteUrl = () => {
                return apiService.lead.getDownloadQuoteUrl($scope.leadId);
            };

            $scope.handleDeepEvent = (event) => {
                if (event.eventType === 'change') {
                    if (event.parameters.value !== _.get($scope.leadData, event.field.deepKey)) {
                        _.set($scope.leadData, event.field.deepKey, event.parameters.value);

                        // we always want to remove model_id if community_id changed..
                        if (event.field.deepKey === 'property_data.community_id') {
                            delete $scope.leadData.property_data.model_id;
                        }
                        if (!event.parameters.value) {
                            if (event.field.deepKey === 'property_data.uncommon_or_no_heating_source') {
                                $scope.leadData.property_data.unusual_heating = false;
                                $scope.leadData.property_data.wood_stove = false;
                            } else if (event.field.deepKey === 'property_data.residence_type_partial') {
                                $scope.leadData.property_data.residence_type = 'primary';
                                $scope.leadData.property_data.non_primary_residence_or_under_construction = false; // make sure toggle that opens the modal slides back. special case
                            } else if (
                                event.field.deepKey === 'property_data.non_primary_residence_or_under_construction'
                            ) {
                                $scope.leadData.property_data.residence_type = 'primary';
                                $scope.leadData.property_data.house_under_construction = false;
                            } else if (event.field.deepKey === 'property_data.smart_home') {
                                $scope.leadData.property_data.smart_home_fire_alarm = 'No';
                                $scope.leadData.property_data.smart_home_burglar_alarm = 'No';
                                $scope.leadData.property_data.smart_home_water_leak_device = 'No';
                                $scope.leadData.property_data.smoke_alarm_type = 'Local';
                            } else if (
                                event.field.deepKey === 'property_data.heating_oil_tank.has_tank' &&
                                !event.parameters.value
                            ) {
                                $scope.leadData.property_data.heating_oil_tank.has_under_ground_tank = false;
                                $scope.leadData.property_data.heating_oil_tank.is_professionally_decommissioned = false;
                                $scope.leadData.property_data.heating_oil_tank.year_installed = undefined;
                            } else if (event.field.deepKey === 'property_data.wind_mitigation_discount') {
                                $scope.leadData.property_data.other_wind_loss_prevention = 'none';
                                $scope.leadData.property_data.roofCoveringSCBC = false;
                                $scope.leadData.property_data.roofAttachmentSCBC = false;
                                $scope.leadData.property_data.roofToWallSCBC = false;
                            } else if (event.field.deepKey === 'property_data.wind_mitigation_tile') {
                                $scope.leadData.property_data.wind_mitigation = 'other';
                            } else if (event.field.deepKey === 'personal_information.second_insured_is_toggled') {
                                $scope.leadData.personal_information.second_insured.first_name = '';
                                $scope.leadData.personal_information.second_insured.last_name = '';
                                $scope.leadData.personal_information.second_insured.date_of_birth = '';
                            } else if (
                                event.field.deepKey === 'property_data.smart_home_expected_existing_partner_customer'
                            ) {
                                $scope.leadData.property_data.smart_home_expected_existing_partner_customer = true;
                                // at simplisafe cobrand flow, the simplisafe tile at prelim-discounts toggled as default when server value smart_home_expected_existing_partner_customer is undefined.
                            } else if (event.field.deepKey === 'property_data.no_smart_home_kit') {
                                $scope.leadData.property_data.smart_home_starter_kit = false;
                            } else if (event.field.deepKey === 'property_data.smart_home_selected_kit') {
                                $scope.leadData.property_data.smart_home_selected_kit = null;
                            }
                        }
                    }
                    return;
                }

                if (event.eventType === 'changeWithAction') {
                    if (event.parameters.value !== _.get($scope.leadData, event.field.deepKey)) {
                        const originalValue = _.get($scope.leadData, event.field.deepKey);
                        return $scope.showModal(event.field.actionModal).closePromise.then((result) => {
                            // revert if user canceled the operation
                            // Timeout is required to let angular rerender scope changes. This is needed so that components such as deepToggle
                            // will detect a change in the case of a revert and update the display properly.
                            $timeout(() => _.set($scope.leadData, event.field.deepKey, event.parameters.value));
                            if (_.isArray(result.value) || (!!result.value && !!result.value.eventType)) {
                                // modals can trigger another event
                                const allEvents = [].concat(result.value); // support list of events
                                _.each(allEvents, (e) => {
                                    // if setting the original event deepkey, also use a timeout so line 494 doesn't overwrite it
                                    if (e.eventType === 'change' && e.field.deepKey === event.field.deepKey) {
                                        $timeout(() => $scope.handleDeepEvent(e), 0);
                                    } else {
                                        $scope.handleDeepEvent(e);
                                    }
                                }); // apply all events
                                // save all events - after potential other timeouts
                                $timeout(() => $scope.save(false), 1);
                            } else if (originalValue !== event.parameters.value) {
                                // revert since no change was done..
                                $timeout(() => _.set($scope.leadData, event.field.deepKey, originalValue));
                            }
                        });
                    }
                }

                if (event.eventType === 'claimsToAdd') {
                    $scope.claimsToAdd = event.parameters.value;
                    return;
                }

                if (event.eventType === 'progressiveLinkToAdd') {
                    $scope.progressiveLink = event.parameters.value;
                    return;
                }

                if (event.eventType === 'addressChange') {
                    return $scope.startNewLeadWithAnimation(event.parameters);
                }

                if (event.eventType === 'noop') {
                    $log.info('noop does nothing');
                    return;
                }

                if (event.eventType === 'info' && event.field) {
                    return $scope.showModal(event.field.infoModal);
                }

                if (event.eventType === 'error') {
                    return $scope.handleError(event.parameters.error);
                }

                if (event.eventType === 'animateNext') {
                    if (event.nextPage) {
                        return navService.animateToPage(event.nextPage, 0.5);
                    }
                    return navService.animateToPage($scope.activePage + 1, 0.5);
                }

                if (event.eventType === 'confirmation') {
                    return $scope.setConfirmation(event.parameters.confirmation);
                }

                if (event.eventType === 'changeConfirmation') {
                    return _.set($scope.confirmation, event.field.deepKey, event.parameters.value);
                }

                if (event.eventType === 'changeHideNav') {
                    $scope.hideNav = event.parameters.value;
                    return;
                }

                if (event.eventType === 'infoMouseLeave' || event.eventType === 'infoMouseOver') {
                    $log.info('IGNORING INFO_MOUSE_LEAVE AND INFO_MOUSE_IN!!!!'); // someone forgot to implement this. not sure what it should do. currently logging false errors.
                    return;
                }

                if (event.eventType === 'backToLanding') {
                    // todo: remove once we resolve the routing issue
                    return $scope.backToLanding();
                }

                if (event.eventType === 'errorBack') {
                    // todo: remove once we resolve the routing issue
                    return $scope.errorBackButton();
                }

                if (event.eventType === 'backToProductSelect') {
                    return navService.animateToPage(event.nextPage, -0.5);
                }

                if (event.eventType === 'errorDispute') {
                    // todo: remove once we resolve the modals issue
                    return $scope.errorDisputeButton();
                }

                if (event.eventType === 'identify') {
                    if (event.parameters && event.parameters.identity) {
                        const analyticsProps = {};
                        Object.assign(analyticsProps, event.parameters.identity, {
                            producerId: $scope.flags.producer_id || $scope.flags.producerId,
                            organizationId: $scope.flags.organization_id,
                            producerQuoteId: $scope.leadId
                        });
                        analyticsService.identify($scope.leadId, analyticsProps);
                        fullStoryService.identify($scope.leadId, event.parameters.identity);
                    }
                    return;
                }

                if (event.eventType === 'track') {
                    if (event.field.actionTracking) {
                        analyticsService.track(
                            event.field.actionTracking,
                            event.field.actionTrackingPayload ? event.field.actionTrackingPayload : {}
                        );
                    }
                    return;
                }

                if (event.eventType === 'updatePreferredContactMethod') {
                    $scope.confirmedCoverages.push(event.field.actionTrackingPayload.type);
                    return;
                }
                if (event.eventType === 'show-modal') {
                    // 'info' doesn't handle events in result, and 'action' automatically moves to next page
                    // so this eventType simply shows a modal and handles any events it pushes to the queue
                    return $scope.showModal(event.modal).closePromise.then((result) => {
                        if (_.isArray(result.value) || (!!result.value && !!result.value.eventType)) {
                            // modals can trigger another event
                            const allEvents = [].concat(result.value); // support list of events
                            _.each(allEvents, (e) => {
                                $scope.handleDeepEvent(e);
                            });
                        }
                    });
                }

                if (event.eventType === 'action' && event.field) {
                    $scope.coverageType = event.field.type;
                    return $scope.showModal(event.field.actionModal).closePromise.then((result) => {
                        // revert if user canceled the operation
                        if (_.isArray(result.value) || (!!result.value && !!result.value.eventType)) {
                            // modals can trigger another event
                            const allEvents = [].concat(result.value); // support list of events
                            _.each(allEvents, (e) => {
                                $scope.handleDeepEvent(e);
                            }); // apply all events
                            let moveToNextPage = false;
                            if (event.parameters) {
                                if (event.parameters.doNotSave) {
                                    return result;
                                }
                                moveToNextPage = event.parameters.moveToNextPage || false;
                            }
                            $scope.save(moveToNextPage); // save all events
                            return result;
                        } // in this scenario we don't care about all the other scenarios. no revert..
                    });
                }
                // if (event.eventType === 'bind') {
                //   return bind(event.parameters.data);
                // }
                if (event.eventType === 'modal-save') {
                    return $scope.save(false);
                }
                if (event.eventType === 'save') {
                    if (event.parameters.deductibleChangedAutomatically) {
                        $scope.deductibleChangedAutomatically = true;
                    }
                    return $scope.save(event.parameters.moveToNextPage);
                }
                if (event.eventType === 'next') {
                    return $scope.next();
                }
                if (event.eventType === 'back') {
                    return $scope.back();
                }

                if (event.eventType === 'lendersSent') {
                    $scope.totalMortgageUpdates += 1;
                    $scope.reachedLenderUpdateLimit = $scope.totalMortgageUpdates >= 10;
                    return;
                }

                if (event.eventType === 'infoMouseOver' || event.eventType === 'infoMouseLeave') {
                    return;
                }

                $log.error(new Error(`unhandle deep event ${event.eventType}`));
                $log.info('this is event', event);
            };

            $scope.isLoaded = () => !$scope.loadingApi && $scope.loaded;

            const unwatchIsLoaded = $scope.$watch(
                () => $scope.isLoaded(),
                (isLoaded) => {
                    if (isLoaded) {
                        $timeout(() => {
                            // give angular time to render the app
                            $('.preloaded-fadeout').remove();
                            $('body').removeClass('preloading');
                            // Initialize user activity tracking
                            userActivityService.init();
                            unwatchIsLoaded();
                        }, 1);
                    }
                }
            );

            $scope.handleError = function (err) {
                $scope.loadingApi = false;
                if (err.data && _.isArray(err.data) && !navService.isInMidpoint()) {
                    // When users are in a midpoint the errors cannot be displayed in the page
                    // since the navigation service is not in a valid page. For cases like that
                    // we will send the user directly to the error page.
                    let foundOneField = false;
                    err.data.forEach((error) => {
                        const activePageFields = _.values($scope.getActivePageView().fields);
                        const field = _.find(activePageFields, {
                            deepKey: error.field
                        });
                        if (field) {
                            foundOneField = true;
                            field.has_error = true;
                        }
                    });
                    if (!foundOneField) {
                        $log.info(err); // this information is important to log.
                        $scope.handleError({});
                    }
                } else {
                    $scope.stopSaving();
                    $scope.err = err.data || {};
                    const beforeErrorPage = Math.floor($scope.activePage);
                    const prebindCodes = ['high_non_catastrophic_risk_referral', 'non_catastrophic_water_risk'];
                    const shouldRedirectToPreBindInspectionPage =
                        prebindCodes.includes(_.get(err, 'data.code')) &&
                        featureToggle.isProducerFlow() &&
                        !featureToggle.isHo5Product();
                    const errorPage = _.findIndex($scope.pages, {
                        type: shouldRedirectToPreBindInspectionPage ? 'pre-bind-inspection' : 'error'
                    });
                    const fromLanding = beforeErrorPage === 0 || !beforeErrorPage;
                    $scope.isRetargetingLeadError = partnerService.hasPartnerPage && fromLanding;
                    // Avoid getting stuck on the error page when trying to go back.
                    if (beforeErrorPage !== errorPage) {
                        $scope.beforeErrorPage = beforeErrorPage;
                        navService.animateToPage(errorPage, 0.5);
                        analyticsService.track('hit error page', $scope.err);
                    }
                }
            };

            const displayError = featureToggle.getDisplayError();
            if (displayError) {
                if (displayError === 'true') {
                    apiService.test.error.list().then((result) => {
                        $log.info('these are the errors', result.data);
                    });
                } else {
                    apiService.test.error.get(displayError).then(
                        (result) => {
                            window._.find($scope.pages, {
                                type: 'error'
                            }).includeOnDom = true;

                            $log.info('got display error', displayError, result.data);
                            $scope.err = result.data;
                        },
                        () => {
                            $log.info('no such error', displayError);
                        }
                    );
                }
            }

            $scope.stopSaving = function () {
                $scope.saving = false;
                $scope.showSaving = false;
            };

            $scope.processQuote = function (quote) {
                $scope.leadData.quote = _.clone(quote);
            };

            $scope.errorBackButton = function () {
                // consider always using window history back.. why? because if user clicks button back, and then browser back.. behavior is weird.
                if ($scope.beforeErrorPage !== undefined) {
                    navService.animateToPage($scope.beforeErrorPage, -0.5);
                } else {
                    window.history.back(); // fallback to browser history back...
                }
            };

            $scope.errorDisputeButton = function () {
                $scope.showModal('dispute');
            };

            $scope.backToLanding = function () {
                $window.document.title = 'Hippo';
                $scope.additionalInfo = {
                    producer: {}
                };
                $scope.err = null;
                const raterVersion = undefined;
                $scope.pages = WorkflowPlan.getPages(
                    $scope.defaultState,
                    $scope.defaultProduct,
                    $scope.defaultCarrier,
                    $scope.flowCustomizationKey(),
                    raterVersion
                );
                $scope.modals = WorkflowPlan.getModals(
                    $scope.defaultState,
                    $scope.defaultProduct,
                    $scope.defaultCarrier,
                    $scope.flowCustomizationKey(),
                    raterVersion
                );
                $scope.confirmationShow = false;
                $scope.confirmationSmall = false;
                $scope.confirmationLarge = false;
                delete $location.search().leadId;
                delete $scope.leadId;
                compiledPages = {};
                cacheService.reset();

                // Reset lead data and lead id
                if (!featureToggle.isRetargetingFlow()) {
                    $scope.leadData = $scope.getInitialState();
                    navService.clearSession();
                }

                $scope.prelim = {};

                if ($scope.flags.hippoCom) {
                    window.location.href = 'https://www.hippo.com/';
                } else if (featureToggle.isRetargetingFlow() && $scope.isRetargetingLeadError) {
                    window.location.href = '/';
                } else {
                    navService.animateToPage(pagesService.landingPage.get().key, -0.5);
                }
            };

            $scope.infoClick = function (field, $event) {
                $scope.showModal(field.infoModal, $event);
            };

            $scope.validateField = function (field, page) {
                const deepKey = field.deepKey;
                const fieldValue = window._.get($scope.leadData, deepKey);

                // if a tile or toggle and has a 'false' state, but value is undefined on model, lets just put it..
                if (
                    fieldValue === undefined &&
                    (field.type === 'toggle' || field.type === 'tile') &&
                    (field.value === undefined || _.isObject(field.value))
                ) {
                    if (field.value) {
                        _.set($scope.leadData, field.deepKey, field.value.false);
                    } else {
                        _.set($scope.leadData, field.deepKey, false);
                    }
                    return true; // we set the value right now.. :)
                } else if (
                    typeof fieldValue !== 'undefined' &&
                    fieldValue !== '' &&
                    fieldValue !== null &&
                    (!_.endsWith(deepKey, 'previousAddress') || (fieldValue !== false && !_.isEmpty(fieldValue)))
                ) {
                    const elem = utilsService.getElementForField(
                        {
                            deepKey
                        },
                        page
                    );
                    if (elem) {
                        if (elem.nodeName === 'INPUT' && elem.validity && elem.validity.valid) {
                            return true;

                            // for live address elem is a DIV and the first child is the actual input
                        } else if (
                            elem.children[0] &&
                            elem.children[0].nodeName === 'INPUT' &&
                            elem.children[0].validity &&
                            elem.children[0].validity.valid
                        ) {
                            return true;
                        }
                    }
                }
                return false;
            };

            let compiledPages = {};

            function compileActivePage() {
                const pageType =
                    $scope.pages && $scope.pages[$scope.activePage] ? $scope.pages[$scope.activePage].type : '';
                $scope.isOutsideLeadFlow = _.includes(HIPPO_CONSTS.PAGE_TYPES_OUTSIDE_LEAD_FLOW, pageType);
                $scope.createdFromQuote =
                    ($scope.pages[$scope.activePage] || {}).key === 'checkout' && $scope.flags.retargeting === 'true';

                const pageTemplate = $scope.pages[$scope.activePage];
                _.each(compiledPages, (p) => {
                    p.active = pageTemplate && p.key === pageTemplate.key;
                });
                if (pageTemplate) {
                    compiledPages[pageTemplate.key] = pageGenerator.generatePage(pageTemplate, $scope.pageViewData);
                    compiledPages[pageTemplate.key].animating = $scope.animating;
                    compiledPages[pageTemplate.key].active = true;

                    // pages can turn off the back button (HIP-9047)
                    $scope.hideBackButton = compiledPages[pageTemplate.key].hideBackButton;

                    const eventOnPageActive = [].concat(compiledPages[pageTemplate.key].eventOnPageActive);
                    const firstUnhandled = _.find(
                        eventOnPageActive,
                        (event) => event && (!event.once || !cacheService.contains(event))
                    );
                    if (typeof firstUnhandled === 'object') {
                        cacheService.push(firstUnhandled);
                        $scope.handleDeepEvent(firstUnhandled);
                    }
                }
            }
            const scrollKeys = {
                37: 1,
                38: 1,
                39: 1,
                40: 1
            };

            function preventDefault(e) {
                e = e || window.event;
                if (e.preventDefault) {
                    e.preventDefault();
                }
                e.returnValue = false;
            }

            function preventDefaultForScrollKeys(e) {
                if (scrollKeys[e.keyCode]) {
                    preventDefault(e);
                    return false;
                }
            }

            function disableScroll() {
                if (window.addEventListener) {
                    // older FF
                    window.addEventListener('DOMMouseScroll', preventDefault, false);
                }
                document.addEventListener('wheel', preventDefault, {
                    passive: false
                }); // Disable scrolling in Chrome
                document.addEventListener('touchmove', preventDefault, {
                    passive: false
                });
                window.onwheel = preventDefault; // modern standard
                window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
                window.ontouchmove = preventDefault; // mobile
                document.onkeydown = preventDefaultForScrollKeys;
            }

            function enableScroll() {
                if (window.removeEventListener) {
                    window.removeEventListener('DOMMouseScroll', preventDefault, false);
                }
                document.removeEventListener('wheel', preventDefault, {
                    passive: false
                }); // Enable scrolling in Chrome
                document.removeEventListener('touchmove', preventDefault, {
                    passive: false
                }); // Enable scrolling in Chrome
                window.onmousewheel = document.onmousewheel = null;
                window.onwheel = null;
                window.ontouchmove = null;
                document.onkeydown = null;
            }
            $scope.holdScrollWhileRendering = (holdTime) => {
                disableScroll();
                $timeout(enableScroll, holdTime);
            };

            $scope.$watch(
                '{confirmedCoverages: confirmedCoverages, leadData: leadData, reachedLenderUpdateLimit: reachedLenderUpdateLimit, flags: backendFlags, claimsToAdd: claimsToAdd, additionalInfo: additionalInfo, missingFields: missingFields, error:err, prelim: prelim, confirmation: confirmation, leadId: leadId, downloadUrl: getDownloadQuoteUrl(), notionDiscount: notionDiscount, socialData: socialData, progressiveLink: progressiveLink, insuranceApplicationId: data.insuranceApplicationId}',
                function (newValue) {
                    newValue.modals = $scope.modals; // todo: clone the data here.. make sure everything is pure. no page would be able to do data.leadData.X = 'a' once this is cloned..
                    $scope.pageViewData = newValue;
                    if ($scope.activePage && $scope.activePage !== 0.5 && $scope.pages) {
                        producerInFlow.sendQuickNavPageChangeEvent({
                            page: $scope.activePage,
                            template: _.get($scope.pages[$scope.activePage], 'key'),
                            leadId: $scope.leadId,
                            leadData: $scope.leadData
                        });
                    }
                    compileActivePage();
                },
                true
            );

            $scope.$watch('activePage', compileActivePage);
            $scope.$watch('animating', (newValue) => {
                _.each(compiledPages, (p) => {
                    p.animating = newValue;
                });
            });

            $scope.getActivePageView = () => {
                // especially for debugging. $('#pagecontainer').scope().getActivePageView()
                return $scope.getPageView($scope.pages[$scope.activePage]);
            };

            function generatePageKey(func, data) {
                return func(data);
            }

            $scope.getPageView = (pageIndex, cached = true) => {
                /**
                 * accept get dynamic key through key function
                 */
                if (typeof pageIndex.key === 'function') {
                    pageIndex.key = generatePageKey(pageIndex.key, $scope.pageViewData);
                }
                if (!compiledPages[pageIndex.key] || !cached) {
                    compiledPages[pageIndex.key] = pageGenerator.generatePage(
                        pageIndex,
                        $scope.pageViewData,
                        $scope.handleDeepEvent
                    );
                }
                return compiledPages[pageIndex.key];
            }; // dumb implementation for now

            $q.all(loadedPending).then(() => {
                $scope.loaded = true;
            });

            $scope.bootMaterialElements = function () {
                window.shouldRunMaterialize = true;
            };

            // guy : to debug animation, you can write the following in the console
            // $('button').scope().back()
            // $('button').scope().next()

            $scope.next = function () {
                if ($scope.leadId && !$scope.pages[$scope.activePage].skipSave) {
                    $scope.save(true);
                } else {
                    navService.animateToPage($scope.activePage + 1, 0.5);
                }
            };

            $scope.back = function () {
                // https://myhippo.atlassian.net/browse/HIP-1718
                // This fixes an issue with a race condition that happens
                // when the user clicks back more than once while triggering
                // the error page. Since we are still relying on the page index,
                // it is possible that the activePage has been updated to point to the
                // error page before the second back transition happens.
                if (typeof $scope.pages[$scope.activePage] === 'undefined') {
                    return;
                }
                if (
                    $scope.pages[$scope.activePage].type === 'error' ||
                    $scope.pages[$scope.activePage].type === 'pre-bind-inspection'
                ) {
                    return $scope.errorBackButton();
                }
                if (!$scope.pages[$scope.activePage].noBack) {
                    navService.animateToPage($scope.activePage - 1, -0.5);
                    $scope.err = false;
                } else {
                    $scope.backToLanding();
                }
            };

            // Override for back btn
            $scope.openQuickNav = () => {
                $scope.inFlowNavOpen = true;
                producerInFlow.sendQuickNavOpenEvent();
            };
            const getActivePageObj = () => {
                return $scope.pages[$scope.activePage];
            };
            const closeNav = () => {
                $scope.inFlowNavOpen = false;
                $scope.$digest();
            };

            window.scope = $scope;

            /**
             *
             * @param {string} modal - modal name
             * @param event
             * @param type
             */
            $scope.showModal = function (modal, event, type) {
                if (modal in $scope.modals) {
                    const modalName = modal;
                    modal = $scope.modals[modal];
                    const modalScope = $scope.$new(true);
                    modalScope.data = {
                        leadData: $scope.leadData,
                        additionalInfo: $scope.additionalInfo,
                        confirmedCoverages: $scope.confirmedCoverages,
                        leadId: $scope.leadId,
                        page: $scope.pages[$scope.activePage],
                        prelim: $scope.prelim,
                        notionDiscount:
                            (typeof $scope.notionDiscount === 'number' && $scope.notionDiscount) ||
                            $scope.leadData.property_data.starterDiscount,
                        flags: $scope.backendFlags,
                        confirmation: $scope.confirmation,
                        producerUrl: $scope.producerUrl,
                        simplisafeDiscount:
                            $scope.confirmation.simplisafeDiscount ||
                            $scope.leadData.property_data.simplisafeDiscount ||
                            ($scope.leadData.property_data.smart_home_pro_monitoring_kit
                                ? $scope.leadData.property_data.smart_home_pro_monitoring_kit.discount
                                : 0) ||
                            0
                    };

                    // Add this to modal data so we can include in payload
                    if (modal.type === 'contactMethod') {
                        modalScope.data.confirmation = $scope.confirmation;
                        modalScope.data.coverageType = $scope.coverageType;
                    }

                    modalScope.modal = pageGenerator.generatePage(modal, modalScope.data);

                    // Hide In flow nav for producers temporarily when modal is open
                    producerInFlow.sendQuickNavModalOpenEvent();

                    modalScope.generatePage = (data) => pageGenerator.generatePage(modal, data);

                    const directiveName = `${_.kebabCase(modal.type)}-modal`;
                    const dialogConfig = {
                        template: `<${directiveName} modal="modal" generate-page="generatePage(data)" data="data" on-event="handleDeepEvent($event)" modal-confirm-exit></${directiveName}>`,
                        scope: modalScope,
                        appendClassName: 'hippo-' + directiveName + ' ' + _.kebabCase(modalName),
                        name: 'hippo-' + directiveName,
                        preCloseCallback: function (reason) {
                            // $escape , $document // guy - using this callback this is a trick to catch all close events. not all are caught by 'closeThisDialog'
                            $log.info('preclose hook is running in ctrl.js. reason is: ', reason);
                            // Show In flow nav for producers when modal is closed
                            producerInFlow.sendQuickNavModalCloseEvent();
                            if (typeof reason !== 'object') {
                                return $(`[modal-confirm-exit]`).scope().externalTriggerClose(reason);
                            }
                            return true;
                        }
                    };
                    const closeByEscape = modalScope.modal.closeByEscape;
                    const closeByDocument = modalScope.modal.closeByDocument;

                    if (typeof closeByEscape === 'boolean') {
                        dialogConfig.closeByEscape = closeByEscape;
                    }

                    if (typeof closeByDocument === 'boolean') {
                        dialogConfig.closeByDocument = closeByDocument;
                    }

                    return ngDialog.open(dialogConfig);
                } else {
                    $log.error(new Error('modal ' + modal + ' does not exist, but was requested'));
                }
                if (event) {
                    event.stopPropagation();
                    event.preventDefault();
                }
            };

            $scope.$watch('leadId', (newValue, oldValue) => {
                if (newValue && oldValue !== newValue) {
                    const address = _.get($scope.leadData, 'personal_information.address') || {};

                    const analyticsProps = {};
                    Object.assign(analyticsProps, address, {
                        producerId: $scope.flags.producer_id || $scope.flags.producerId,
                        producerQuoteId: $scope.leadId,
                        organizationId: $scope.flags.organization_id
                    });

                    analyticsService.identify($scope.leadId, analyticsProps);
                    fullStoryService.identify($scope.leadId, address);
                }
            });
        }
    );
